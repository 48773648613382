import React, { useState, useEffect } from "react";
import PengaturanPDF from "../components/PengaturanPDF";
import axios from "axios";
import Swal from "sweetalert2";

function Pengaturan() {
  const [berat, setBerat] = useState("");
  const [penyusutan, setPenyusutan] = useState("");
  const [error, setError] = useState("");

  const validateNumberInput = (value) => {
    const regex = /^[0-9]*[.]?[0-9]*$/;
    return regex.test(value);
  };

  const handleBeratChange = (e) => {
    const value = e.target.value;
    if (validateNumberInput(value)) {
      setBerat(value);
      setError("");
    } else {
      setError("Input Berat Wadah harus berupa angka atau desimal.");
    }
  };

  const handlePenyusutanChange = (e) => {
    const value = e.target.value;
    if (validateNumberInput(value)) {
      setPenyusutan(value);
      setError("");
    } else {
      setError("Input Penyusutan harus berupa angka atau desimal.");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pengaturan`)
      .then((response) => {
        setBerat(response.data.berat_wadah);
        setPenyusutan(response.data.penyusutan * 100);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSave = () => {
    const penyusutanDesimal = penyusutan / 100;

    axios
      .put(`${process.env.REACT_APP_API_URL}/pengaturan`, {
        berat_wadah: berat,
        penyusutan: penyusutanDesimal,
      })
      .then(() => {
        Swal.fire({
          title: "Berhasil!",
          text: "Data berhasil di update.",
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Gagal!",
          text: `Terjadi kesalahan saat menyimpan data. \nError: ${error}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className='w-full'>
      <div className='p-4'>
        <h1 className='md:text-4xl text-2xl font-bold'>Pengaturan</h1>
      </div>

      <PengaturanPDF />

      <div className='px-4 md:mt-8 mt-4 flex flex-col'>
        <div className='md:text-lg text-base font-semibold md:mb-6 mb-4'>
          Input Potongan Berat Wadah dan Penyusutan
        </div>
        <div className='flex flex-row md:gap-4 gap-2 md:text-base text-sm'>
          <div className='w-1/2 flex flex-row md:gap-4 gap-3 items-center'>
            <label className='md:w-1/6'>Berat Wadah</label>
            <div className='relative w-1/2'>
              <input
                type='text'
                name='berat'
                value={berat}
                onChange={handleBeratChange}
                placeholder='0'
                className='w-full border border-gray-300 p-2 pr-10'
              />
              <span className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
                Kg
              </span>
            </div>
          </div>
          <div className='w-1/2 flex flex-row md:gap-4 gap-3 items-center'>
            <label className='md:w-1/6'>Penyusutan</label>
            <div className='relative w-1/2'>
              <input
                type='text'
                name='penyusutan'
                value={penyusutan}
                onChange={handlePenyusutanChange}
                placeholder='0'
                className='w-full border border-gray-300 p-2 pr-10'
              />
              <span className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
                %
              </span>
            </div>
          </div>
        </div>
        {/* Tampilkan error jika input tidak valid */}
        {error && <div className='text-red-500 mt-2'>{error}</div>}

        <div className='justify-start md:text-base text-sm'>
          <button
            onClick={handleSave}
            className='bg-green-500 text-white px-4 md:py-2 py-1.5 md:mt-12 mt-6 rounded-lg'>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
}

export default Pengaturan;
