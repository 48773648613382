import React, { useState } from "react";
import TabelHitung from "../components/TabelHitung";
import ModalHitungBerat from "../components/ModalHitungBerat";

function HitungBerat() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className='w-full'>
      <div className='p-4'>
        <h1 className='md:text-4xl text-2xl font-bold'>Hitung Berat</h1>
      </div>
      <div className='w-full px-5'>
        <div className='flex flex-row justify-between py-2 items-center'>
          <div className='md:text-2xl text-base font-semibold'>List Data</div>
          <button
            className='rounded-lg md:px-4 px-2 md:py-2 py-1.5 md:text-base text-sm bg-neutral-300 text-neutral-900 font-semibold'
            onClick={openModal}>
            Hitung Berat
          </button>
        </div>
        <div className='my-3 w-full'>
          <TabelHitung />
        </div>
      </div>

      <ModalHitungBerat modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </div>
  );
}

export default HitungBerat;
