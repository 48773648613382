import React, { useEffect, useState } from "react";
import TabelAkun from "../components/TabelAkun";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function Akun() {
  const [formVisible, setFormVisible] = useState(false);
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/users`)
      .then((res) => res.json())
      .then((barang) => setData(barang))
      .catch((err) => console.log(err));
  }, []);

  const handleDetail = (username) => {
    const storedUser = sessionStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;

    if ((user && user.role === "admin") || user.username === username) {
      const itemToDetail = data.find((item) => item.username === username);
      if (itemToDetail) {
        setFormData(itemToDetail);
        setIsDetailMode(true);
        setFormVisible(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Data Tidak Ditemukan",
          text: "Pengguna yang dimaksud tidak ditemukan",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Akses Ditolak",
        text: "Hanya admin atau pemilik akun yang dapat melihat detail akun ini",
      });
    }
  };

  const handleHideForm = () => {
    setFormVisible(false);
    setIsDetailMode(false);
    setFormData({});
  };

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Akun</h1>
        {formVisible && (
          <button
            className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'
            onClick={handleHideForm}>
            Kembali
          </button>
        )}
      </div>
      {!formVisible && (
        <div>
          <div className='flex flex-row justify-between px-5 py-2 items-center'>
            <div className='md:text-2xl text-bas font-semibold'>List Data</div>
            <Link
              to='/form-akun'
              className='rounded-lg md:px-4 px-2 md:py-2 py-1.5 md:text-base text-sm bg-neutral-300 text-neutral-900 font-semibold'>
              Buat Akun
            </Link>
          </div>
          <div className='my-3 px-5'>
            <TabelAkun data={data} onDetail={handleDetail} />
          </div>
        </div>
      )}
      {formVisible && (
        <div className='px-5 md:py-4 py-2 md:text-base text-xs'>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Nama User</label>
              <input
                type='text'
                name='nama'
                value={formData.nama_user}
                readOnly={isDetailMode}
                className='w-full border border-gray-300 p-2 mb-2'
              />
            </div>
            <div className='w-1/2'>
              <label>No. HP</label>
              <input
                type='text'
                name='no_hp'
                value={formData.hp}
                readOnly={isDetailMode}
                className='w-full border border-gray-300 p-2 mb-2'
              />
            </div>
          </div>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Username</label>
              <input
                type='text'
                name='username'
                value={formData.username}
                readOnly={isDetailMode}
                className='w-full border border-gray-300 p-2 mb-2'
              />
            </div>
            <div className='w-1/2'>
              <label>Password</label>
              <div className='relative'>
                <input
                  type={showPassword ? "text" : "password"}
                  name='password'
                  value={formData.password}
                  readOnly={isDetailMode}
                  className='w-full border border-gray-300 p-2 mb-2'
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute right-2 top-2 text-gray-500'>
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-row md:gap-4 gap-2'>
            <div className='w-1/2'>
              <label>Role</label>
              <input
                name='role'
                value={formData.role}
                readOnly={isDetailMode}
                className='w-full border border-gray-300 p-2 mb-2'></input>
            </div>
            <div className='w-1/2'>
              <label>Foto Profil</label>
              <input
                type='file'
                name='fotoProfil'
                accept='image/*'
                className='w-full border border-gray-300 p-1.5 mb-2'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Akun;
