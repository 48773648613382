import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function FormPengirimanBarang() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    no_seri: "",
    jenis_hhbk: "",
    tujuan: "",
    tanggal_terbit: "",
    volume: "",
    satuan: "",
    kondisi_barang: "bagus",
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    const formattedValue =
      name === "tanggal_terbit"
        ? new Date(value).toISOString().split("T")[0]
        : value;

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleSubmitForm = async () => {
    const formattedDate = new Date(formData.tanggal_terbit)
      .toISOString()
      .split("T")[0];

    const dataToSend = {
      ...formData,
      tanggal_terbit: formattedDate,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    console.log("Data to send:", dataToSend);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/kirim_barang`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      Swal.fire({
        title: "Berhasil!",
        text: "Data berhasil disimpan.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/pengiriman-barang");
      });
    } catch (err) {
      console.error("Error:", err);
      Swal.fire({
        title: "Gagal!",
        text: `Terjadi kesalahan saat menyimpan data. \nError: ${err.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between items-center'>
        <h1 className='md:text-4xl text-2xl font-bold'>Pengiriman Barang</h1>
        <Link
          to='/pengiriman-barang'
          className='bg-red-500 text-white px-4 py-2 rounded'>
          Kembali
        </Link>
      </div>
      <div className='flex flex-col p-5 gap-4 w-full'>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Nomor Seri</div>
          <input
            type='text'
            name='no_seri'
            placeholder='Nomor Seri HHBK'
            value={formData.no_seri}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Jenis HHBK</div>
          <input
            type='text'
            name='jenis_hhbk'
            placeholder='Jenis HHBK'
            value={formData.jenis_hhbk}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Tujuan</div>
          <input
            type='text'
            name='tujuan'
            placeholder='Tujuan'
            value={formData.tujuan}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Volume</div>
          <input
            type='text'
            name='volume'
            placeholder='Volume'
            value={formData.volume}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Satuan</div>
          <input
            type='text'
            name='satuan'
            placeholder='Satuan'
            value={formData.satuan}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Tanggal Terbit</div>
          <input
            type='date'
            name='tanggal_terbit'
            placeholder='Tanggal Terbit'
            value={formData.tanggal_terbit}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'
          />
        </div>
        <div className='flex flex-row items-center gap-4 w-full'>
          <div className='w-[180px]'>Kondisi Barang</div>
          <select
            name='kondisi_barang'
            value={formData.kondisi_barang}
            onChange={handleFormChange}
            className='border p-2 rounded w-full'>
            <option value='bagus'>Bagus</option>
            <option value='rusak'>Rusak</option>
            <option value='bocor'>Bocor</option>
          </select>
        </div>
        <div className='flex justify-end'>
          <button
            onClick={handleSubmitForm}
            className='bg-blue-500 text-white px-4 py-2 rounded'>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormPengirimanBarang;
