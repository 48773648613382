import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import CetakDetailHitung from "../components/CetakDetailHitung";

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    { id: "uuid", label: "UUID", numeric: false, align: "left" },
    {
      id: "nama",
      label: "Nama Mitra/Personal",
      numeric: false,
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      numeric: false,
      align: "center",
    },
    {
      id: "totalBeratBersih",
      label: "Total Berat Bersih",
      numeric: false,
      align: "center",
    },
    { id: "aksi", label: "Aksi", numeric: false, align: "center" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            style={{ minWidth: headCell.minWidth }}>
            <div className='md:text-base text-xs font-semibold w-full'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TabelHitung() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataMitra, setDataMitra] = useState([]);
  const [dataMitraDetail, setDataMitraDetail] = useState([]);
  const [totalBeratBersihPerMitra, setTotalBeratBersihPerMitra] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra_detail`)
      .then((res) => res.json())
      .then((data) => setDataMitraDetail(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra`)
      .then((res) => res.json())
      .then((data) => setDataMitra(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (dataMitra.length > 0 && dataMitraDetail.length > 0) {
      const result = dataMitra.map((mitra) => {
        const totalBeratBersih = dataMitraDetail
          .filter((detail) => detail.uuid === mitra.uuid)
          .reduce((acc, curr) => acc + curr.berat_bersih, 0);

        return {
          uuid: mitra.uuid,
          nama: mitra.nama_mitra,
          status: mitra.status,
          totalBeratBersih: totalBeratBersih,
        };
      });
      setTotalBeratBersihPerMitra(result);
    }
  }, [dataMitra, dataMitraDetail]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = useMemo(() => {
    return totalBeratBersihPerMitra.filter(
      (row) =>
        row.nama?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        row.uuid?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, totalBeratBersihPerMitra]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const visibleRows = useMemo(
    () =>
      filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, filteredData]
  );

  const onEdit = (row) => {
    navigate(`/edit-hitung-berat/${row.status}/${row.uuid}`);
  };

  const onDetail = (row) => {
    navigate(`/detail-hitung-berat/${row.uuid}`);
  };

  // Uncomment this if you want to use handleCetak
  // const handleCetak = (id) => {
  //   alert(`Cetak for UUID: ${id}`);
  // };

  return (
    <div className='flex flex-col'>
      <div className='flex justify-end mb-4'>
        <TextField
          label='Cari Nama atau UUID'
          variant='outlined'
          size='small'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{
                width: {
                  xs: "200%",
                  sm: "150%",
                  md: "100%",
                },
              }}
              size='medium'>
              <EnhancedTableHead />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow key={row.uuid}>
                    <TableCell>
                      <div className='md:text-sm text-xs'>{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <div className='md:text-sm text-xs'>{row.uuid}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.nama}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.status}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.totalBeratBersih}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs flex flex-row gap-2 justify-center'>
                        <Button variant='outlined' size='small' onClick={() => onEdit(row)}>
                          Edit
                        </Button>
                        <Button variant='contained' size='small' onClick={() => onDetail(row)}>
                          Detail
                        </Button>
                        <CetakDetailHitung uuid={row.uuid} nama={row.nama} tableMode={true} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                fontSize: "0.75rem",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
                fontSize: "0.75rem",
              },
              ".MuiTablePagination-select": {
                fontSize: "0.75rem",
              },
            }}
          />
        </Paper>
      </Box>
    </div>
  );
}
