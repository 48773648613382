import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function EnhancedTableHead() {
  const headCells = [
    { id: "no", label: "No", numeric: false, align: "left" },
    {
      id: "nama_user",
      label: "Nama User",
      numeric: false,
      align: "center",
    },
    {
      id: "username",
      label: "Username",
      numeric: false,
      align: "center",
    },
    {
      id: "hp",
      label: "No. HP",
      numeric: false,
      align: "center",
    },
    {
      id: "role",
      label: "Role",
      numeric: false,
      align: "center",
    },
    { id: "aksi", label: "Aksi", numeric: false, align: "center" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            style={{ minWidth: headCell.minWidth }}>
            <div className='md:text-base text-xs font-semibold w-full'>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TabelAkun({ data, onDetail }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const onEdit = (row) => {
    const storedUser = sessionStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;

    if ((user && user.role === "admin") || user.username === row.username) {
      navigate(`/form-edit-akun/${row.username}`);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Akses Ditolak",
        text: "Hanya admin yang boleh mengedit akun lain",
      });
    }
  };

  const filteredData = useMemo(() => {
    return data.filter(
      (row) =>
        row.nama_user.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.username.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, data]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const visibleRows = useMemo(
    () =>
      filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, filteredData]
  );

  return (
    <div className='flex flex-col'>
      <div className='flex justify-end mb-4'>
        <TextField
          label='Search Nama atau Username'
          variant='outlined'
          size='small'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", overflowX: "auto", mb: 2 }}>
          <TableContainer>
            <Table size='medium'>
              <EnhancedTableHead />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className='md:text-sm text-xs'>{index + 1}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.nama_user}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.username}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.hp}</div>
                    </TableCell>
                    <TableCell align='center'>
                      <div className='md:text-sm text-xs'>{row.role}</div>
                    </TableCell>

                    <TableCell align='center'>
                      <div className='md:text-sm text-xs flex flex-row gap-2 justify-center'>
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={() => onEdit(row)}>
                          Edit
                        </Button>
                        <Button
                          variant='contained'
                          size='small'
                          onClick={() => onDetail(row.username)}>
                          Detail
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                fontSize: "0.75rem",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  fontSize: "0.75rem",
                },
              ".MuiTablePagination-select": {
                fontSize: "0.75rem",
              },
            }}
          />
        </Paper>
      </Box>
    </div>
  );
}
