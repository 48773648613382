import React from "react";
import { Link, useParams } from "react-router-dom";
import TabelRekap from "../components/TabelRekap";

function EditHitungBerat() {
  const { uuid } = useParams();

  return (
    <div className='w-full'>
      <div className='p-4 flex flex-row justify-between'>
        <h1 className='md:text-4xl text-2xl font-bold'>Hitung Berat</h1>
        <Link
          to='/'
          className='bg-red-500 text-white px-4 md:py-2 py-1.5 md:mt-4 mt-2 rounded inline'>
          Kembali
        </Link>
      </div>
      <div className='p-5 my-3 w-full'>
        <TabelRekap uuid={uuid} />
      </div>
    </div>
  );
}

export default EditHitungBerat;
