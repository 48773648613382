import React, { useState } from "react";
import html2pdf from "html2pdf.js";

const PengaturanPDF = () => {
  const [kodeUnik, setKodeUnik] = useState("");
  const [jumlah, setJumlah] = useState(0);
  const KODE_PER_HALAMAN = 200;

  const generateKode = (kodeUnik, jumlah) => {
    const kodeList = [];
    for (let i = 1; i <= jumlah; i++) {
      const nomor = String(i).padStart(3, "0");
      kodeList.push(`${kodeUnik}-${nomor}`);
    }
    return kodeList;
  };

  const downloadPDF = async () => {
    const kodeList = generateKode(kodeUnik, jumlah);
    const totalPages = Math.ceil(jumlah / KODE_PER_HALAMAN);

    const element = document.createElement("div");

    for (let page = 0; page < totalPages; page++) {
      const pageDiv = document.createElement("div");
      pageDiv.style.marginBottom = "2rem";

      const startIdx = page * KODE_PER_HALAMAN;
      const endIdx = Math.min(startIdx + KODE_PER_HALAMAN, jumlah);

      for (let i = startIdx; i < endIdx; i++) {
        const kodeElement = document.createElement("div");
        kodeElement.textContent = kodeList[i];
        kodeElement.style.display = "inline-block";
        kodeElement.style.width = "32%";
        kodeElement.style.marginBottom = "75px";
        kodeElement.style.marginRight = "1%";
        kodeElement.style.fontSize = "22px";
        kodeElement.style.pageBreakInside = "avoid";
        pageDiv.appendChild(kodeElement);
      }

      element.appendChild(pageDiv);

      if (page < totalPages - 1) {
        const pageBreak = document.createElement("div");
        pageBreak.style.pageBreakAfter = "always";
        element.appendChild(pageBreak);
      }

      await html2pdf()
        .from(element)
        .set({
          margin: 1,
          filename: `nomor-segel-${page + 1}.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        })
        .save();

      element.innerHTML = "";
    }
  };

  return (
    <div className='px-4 md:mt-8 mt-4 flex flex-col'>
      <div className='md:text-lg text-base font-semibold md:mb-6 mb-4'>
        Cetak nomor segel
      </div>
      <div className='flex flex-row md:gap-4 gap-2 md:text-base text-sm'>
        <div className='w-1/2 flex flex-row md:gap-4 gap-3 items-center'>
          <label className='md:w-1/6'>Kode Unik</label>
          <input
            type='text'
            name='kodeUnik'
            value={kodeUnik}
            onChange={(e) => setKodeUnik(e.target.value)}
            placeholder='abc 000'
            className='w-1/2 border border-gray-300 p-2'
          />
        </div>
        <div className='w-1/2 flex flex-row md:gap-4 gap-3 items-center'>
          <label className='md:w-1/6'>Jumlah</label>
          <input
            type='number'
            name='jumlah'
            value={jumlah}
            onChange={(e) => setJumlah(Number(e.target.value))}
            placeholder='100'
            className='w-1/2 border border-gray-300 p-2'
          />
        </div>
      </div>
      <div className='justify-start md:text-base text-sm'>
        <button
          onClick={downloadPDF}
          className='bg-green-500 text-white px-4 md:py-2 py-1.5 md:my-10 my-6 rounded-lg'>
          Download
        </button>
      </div>
    </div>
  );
};

export default PengaturanPDF;
