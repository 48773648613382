import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();

      if (response.ok) {
        Swal.fire({
          title: "Berhasil!",
          text: "Login berhasil.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          localStorage.setItem("isLoggedIn", true);
          sessionStorage.setItem("user", JSON.stringify(data.user));
          navigate("/");
        });
      } else {
        Swal.fire({
          title: "Gagal!",
          text: data.error || "Terjadi kesalahan.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Error",
        text: "Gagal menghubungi server.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className='flex justify-center items-center md:pt-24 pt-16'>
      <form
        onSubmit={handleLogin}
        className='bg-neutral-100 md:p-6 p-5 rounded-lg shadow-lg sm:w-96 w-4/5 mx-auto'>
        <h2 className='md:text-xl text-lg font-bold md:mb-6 mb-3'>Login</h2>
        <div className='md:mb-4 mb-2 md:text-base text-sm'>
          <label className='block mb-2'>Username</label>
          <input
            type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='w-full border border-gray-300 md:p-2 p-1.5'
            required
          />
        </div>
        <div className='mb-4 md:text-base text-sm'>
          <label className='block mb-2'>Password</label>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='w-full border border-gray-300 md:p-2 p-1.5'
            required
          />
        </div>
        <button
          type='submit'
          className='w-full bg-green-500 text-white font-semibold text-base md:p-2 p-1.5 rounded md:mb-4 mb-2.5'>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
