import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";

function CetakDetailHitung({ uuid, nama, tableMode }) {
  const [dataDetail, setDataDetail] = useState([]);
  const [dataRekap, setDataRekap] = useState([]);
  const tanggal = new Date().toLocaleDateString();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      const { role } = JSON.parse(storedUser);
      setUserRole(role);
    }

    const fetchDataDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}`
        );
        setDataDetail(response.data);
      } catch (error) {
        console.error("Error fetching data detail:", error);
      }
    };

    const fetchDataRekap = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/sum_rekap_mitra_detail?uuid=${uuid}`
        );
        setDataRekap(response.data);
      } catch (error) {
        console.error("Error fetching data rekap:", error);
      }
    };

    fetchDataDetail();
    fetchDataRekap();
  }, [uuid]);

  const handleKlik = async () => {
    if (userRole !== "admin") {
      Swal.fire({
        title: "Akses Ditolak",
        text: "Hanya admin yang boleh mencetak.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      const element = document.createElement("div");
      element.innerHTML = `
        <style>
          @page {
            margin: 1in;
          }
          body {
            margin: 0;
          }
          table {
            width: 100%; 
            font-size: 14px; 
            border-collapse: collapse; 
            border: 1px solid black;
          }
          th {
            background-color: #009879;
            color: white;
            border: 1px solid black;
            padding: 0px 4px 16px 4px; /* padding: atas kanan bawah kiri; */
            text-align: center;
          }
          td {
            border: 1px solid black;
            padding: 0px 4px 16px 4px; 
            text-align: center;
          }
          th:nth-child(2), td:nth-child(2) {
            width: 150px;
          }
        </style>
        
        <div style="margin-bottom: 20px; font-weight: bold;">
          <h2>Nama: ${nama}</h2>
          <p>Tanggal: ${tanggal}</p>
        </div>
        
        <table>
          <thead>
            <tr>
              <th>Nama Penderes/Mitra</th>
              <th>No. Segel</th>
              <th>Berat Total Kotor</th>
              <th>Berat Wadah</th>
              <th>Berat Getah Kotor</th>
              <th>Penyusutan</th>
              <th>Berat Bersih</th>
              <th>Karung</th>
            </tr>
          </thead>
          <tbody>
            ${dataDetail
              .map(
                (row) => `
                  <tr>
                    <td>${row.nama_mitra}</td>
                    <td>${row.no_segel}</td>
                    <td>${row.berat_total_kotor}</td>
                    <td>${row.berat_wadah}</td>
                    <td>${row.berat_getah_kotor}</td>
                    <td>${row.penyusutan}</td>
                    <td>${row.berat_bersih}</td>
                    <td>${row.karung}</td>
                  </tr>
                `
              )
              .join("")}
          </tbody>
        </table>

        <table style="margin-top: 30px;">
          <thead>
            <tr>
              <th>Nama Mitra</th>
              <th>Total Karung</th>
              <th>Total Berat Bersih (kg)</th>
            </tr>
          </thead>
          <tbody>
            ${dataRekap
              .map(
                (row) => `
                  <tr>
                    <td>${row.nama_mitra}</td>
                    <td>${row.totalKarung}</td>
                    <td>${row.totalBeratBersih} kg</td>
                  </tr>
                `
              )
              .join("")}
          </tbody>
        </table>
      `;

      const opt = {
        margin: [0.8, 0.4, 0.5, 0.4],
        filename: `Detail Mitra ${nama}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      };

      html2pdf().set(opt).from(element).save();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <button
      onClick={handleKlik}
      className={`bg-green-600 text-white px-4 ${
        tableMode ? "md:py-1 py-0.5" : "md:py-2 py-1.5"
      }  rounded inline`}>
      Cetak
    </button>
  );
}

export default CetakDetailHitung;
