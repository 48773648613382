import React from "react";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";

function CetakDetailPengiriman({ data, month, year }) {
  const handleKlik = async () => {
    const storedUser = sessionStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;

    if (user && user.role === "admin") {
      try {
        const totalVolume = data.reduce((sum, row) => sum + row.volume, 0);
        const totalSatuan = data.reduce((sum, row) => sum + row.satuan, 0);

        const today = new Date();
        const tanggalCetak = today.toLocaleDateString("id-ID", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });

        const element = document.createElement("div");
        element.innerHTML = `
        <style>
          @page {
            margin: 1in;
          }
          body {
            margin: 0;
          }
          table {
            width: 100%; 
            font-size: 14px; 
            border-collapse: collapse; 
            border: 1px solid black;
          }
          th {
            background-color: #009879;
            color: white;
            border: 1px solid black;
            padding: 0px 4px 16px 4px; 
            text-align: center;
          }
          td {
            border: 1px solid black;
            padding: 0px 4px 16px 4px; 
            text-align: center;
          }
          th:nth-child(2), td:nth-child(2) {
            width: 150px;
          }
          .merged-header {
            text-align: center;
            padding: 8px;
          }
          .judul {
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 25px;
            text-transform: capitalize;
          }
          .subjudul {
            text-align: center;
            font-size: 16px;
            margin-bottom: 20px;
          }
          .footer-container {
            padding-left: 60px;
            padding-right: 60px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
          }
          .cetak-date {
            margin-top: 150px;
            display: flex;
            font-size: 14px;
          }
          .footer-left {
            text-align: left;
          }
          .footer-right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            margin-left: auto;
            font-weight: bold;
          }
          .underline {
            text-decoration: underline;
          }
        </style>

        <div class="judul">LAPORAN ANGKUTAN HASIL HUTAN BUKAN KAYU (HHBK GETAH)
        <br />
        IPHHBK KOPERASI PRODUSEN FLORA MANDIRI MAMASA
        <br />
        ${
          month && year
            ? `BULAN ${month.toUpperCase()} ${year}`
            : year
            ? `TAHUN ${year}`
            : ""
        }</div>

        <table>
          <thead>
            <tr>
              <th rowSpan="2">NO</th>
              <th rowSpan="2">NO SERI SKSHHBK</th>
              <th rowSpan="2">TANGGAL TERBIT</th>
              <th rowSpan="2">JENIS HHBK</th>
              <th rowSpan="2">TUJUAN</th>
              <th colSpan="2">JUMLAH PENGIRIMAN HHBK</th> 
            </tr>
            <tr>
              <th style="width: 100;">VOLUME (Kg)</th>
              <th style="width: 100;">SATUAN (Ton)</th>
            </tr>
          </thead>
          <tbody>
            ${data
              .map(
                (row, index) => `
                  <tr>
                    <td>${index + 1}</td>
                    <td>${row.no_seri}</td>
                    <td>${new Date(
                      row.tanggal_terbit
                    ).toLocaleDateString()}</td>
                    <td>${row.jenis_hhbk}</td>
                    <td>${row.tujuan}</td>
                    <td>${row.volume}</td>
                    <td>${row.satuan}</td>
                  </tr>
                `
              )
              .join("")}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="2" style="font-weight: bold;">Grand Total ${
                month && year
                  ? `Bulan ${month} ${year}`
                  : year
                  ? `Tahun ${year}`
                  : ""
              }</td>
              <td style="font-weight: bold;">${totalVolume.toFixed(2)}</td>
              <td style="font-weight: bold;">${totalSatuan.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <br /><br />
        <div class="footer-container">
          <div class="footer-left"></div>
          <div class="footer-right">
          </div>
        </div>

        <div class="footer-container">
          <div class="footer-left" style="font-weight: bold;">
            GANISPH JIPOKTAH
            <br /><br /><br /><br /><br />
            <span class="underline">TRI MURDIANTO</span>
            <br />
            REG. 14230020169
            <br /><br /><br />
          </div>
          <div class="footer-right" style="font-weight: bold;">
            <span>Mamasa, ${tanggalCetak}</span>
            Direktur
            <br /><br /><br /><br /><br />
            HENRY SIRINA
            <br /><br /><br />
          </div>
        </div>
      `;

        const opt = {
          margin: [0.8, 0.4, 0.5, 0.4],
          filename: "tabel_pengiriman.pdf",
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        };

        html2pdf().set(opt).from(element).save();
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Akses Ditolak",
        text: "Hanya admin yang boleh mencetak",
      });
    }
  };

  return (
    <button
      onClick={handleKlik}
      className='bg-teal-600 text-white px-4 py-[5px] rounded sm:text-lg text-sm border-neutral-500 border-2'>
      Cetak
    </button>
  );
}

export default CetakDetailPengiriman;
