import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

function Header({ toggleSidebar }) {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    console.log(storedUser);
    if (storedUser) {
      const { username } = JSON.parse(storedUser);

      fetch(`${process.env.REACT_APP_API_URL}/users/${username}`)
        .then((res) => res.json())
        .then((data) => {
          setUser(data);
        })
        .catch((err) => console.log(err));
    }
  }, [location]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  return (
    <header className='bg-gray-800 text-white md:py-4 py-3 px-4 flex justify-between items-center fixed top-0 w-full z-50'>
      <div className='flex flex-row gap-8 items-center cursor-pointer'>
        <Link to='/' className='flex flow-row items-center'>
          <img src='/logo.png' alt='Logo' className='h-10 w-10' />
          <span className='font-semibold sm:text-2xl text-xl'>LOGO</span>
        </Link>
        {!isLoginPage && (
          <div className='sm:text-3xl text-2xl' onClick={toggleSidebar}>
            <GiHamburgerMenu />
          </div>
        )}
      </div>

      {!isLoginPage && user && (
        <div className='relative' ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className='flex items-center bg-white rounded-full focus:outline-none'>
            <img
              src={
                user.foto_profil === null
                  ? "/person.png"
                  : `${process.env.REACT_APP_API_URL}/uploads/${user.foto_profil}`
              }
              alt='Profile'
              className='h-10 w-10 rounded-full border-2 border-white'
            />
          </button>

          {isOpen && (
            <div className='absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20'>
              <Link
                to='/akun'
                className='block px-4 py-2 text-gray-700 hover:bg-gray-100 capitalize'>
                {user.username}
              </Link>
              <button
                onClick={handleLogout}
                className='block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left'>
                Keluar
              </button>
            </div>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
