import React from "react";

function Laporan() {
  return (
    <div className='w-full'>
      <div className='p-4'>
        <h1 className='md:text-4xl text-2xl font-bold'>Laporan</h1>
      </div>

      <div className='flex flex-col gap-4 py-4 pl-5 md:pr-10 pr-4'>
        <div className='flex flow-row justify-between items-center'>
          <div className=' md:text-lg text-base'>Laporan Bulanan</div>
          <div className='flex flex-row gap-3 px-4 md:text-base text-xs'>
            <button className=' px-4 py-2 bg-neutral-200 rounded-3xl'>
              Filter
            </button>
          </div>
        </div>
        <div className='h-72 bg-neutral-300'></div>
      </div>

      <div className='flex flex-col gap-4 py-4 pl-4 md:pr-10 pr-4'>
        <div className='flex flow-row justify-between items-center'>
          <div className=' md:text-lg text-base'>Laporan Tahunan</div>
          <div className='flex flex-row gap-3 px-4 md:text-base text-xs'>
            <button className=' px-4 py-2 bg-neutral-200 rounded-3xl'>
              Filter
            </button>
          </div>
        </div>
        <div className='h-72 bg-neutral-300'></div>
      </div>
    </div>
  );
}

export default Laporan;
