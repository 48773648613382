import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function FormEditHitungBerat() {
  const { uuid } = useParams(); // Hanya ambil uuid, hapus status
  const [beratBersih, setBeratBersih] = useState(0);
  const [dataMitraDetail, setDataMitraDetail] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/rekap_mitra_detail/${uuid}`)
      .then((res) => res.json())
      .then((data) => {
        setDataMitraDetail(data);
        setBeratBersih(data.berat_bersih || 0);
      })
      .catch((err) => console.log(err));
  }, [uuid]);

  // Menggunakan useMemo untuk menghitung total berat bersih
  const totalBeratBersih = React.useMemo(() => {
    return dataMitraDetail.reduce((total, detail) => total + detail.berat_bersih, 0);
  }, [dataMitraDetail]);

  // Mengupdate beratBersih saat totalBeratBersih berubah
  useEffect(() => {
    setBeratBersih(totalBeratBersih);
  }, [totalBeratBersih]);

  const handleSave = () => {
    alert(`Saved: ${beratBersih}`);
  };

  return (
    <Box>
      <TextField
        label='Berat Bersih'
        variant='outlined'
        type='number'
        value={beratBersih}
        onChange={(e) => setBeratBersih(e.target.value)}
      />
      <Button onClick={handleSave}>Save</Button>
    </Box>
  );
}
